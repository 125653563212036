<template>
  <span id="cart"
        v-if="loggedIn">
    <span v-if="count > 0"

          class="fa-2x text-left py-0 my-0 mx-4 text-primary"
    >
      <font-awesome-layers class="fa-fw shadow-sm rounded-circle bg-warning">
              <font-awesome-icon
                  :icon="['fas', 'shopping-cart']"
                  @click.prevent="showModal()"
              />
              <font-awesome-layers
                  class="fa-layers-counter fa-layers-top-right carts-layer-counter"
                  size="4x"
                  :transform="{ up: 6 }"
              >{{ count }}</font-awesome-layers
              >
      </font-awesome-layers>
    </span>
    <span v-else
          class="fa-2x text-left py-0 my-0 mx-4">
      <span
          @click.prevent="showModal()"
          class="fa-layers fa-fw py-0 my-0 align-top"
      >
        <font-awesome-icon
            :icon="['fal', 'shopping-cart']"
        />
      </span>
    </span>
    <MountingPortal mountTo="#cart-dialog" name="source" append>
    <v-dialog
        v-model="modalShown"
        class="confirm-dialog"
        bg-transition="fade"
        no-scroll
        @close="hideModal()"
        :classes="{ root: 'modal', content: 'modal-dialog modal-dialog-scrollable modal-lg' }"
    >
      <div class="modal-content border-warning">
        <div class="modal-header bg-warning">
          <h5 class="modal-title">Your current cart</h5>
          <button
              type="button"
              class="close"
              @click="hideModal()"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="count === 0" class="row">
            <div class="col-6 text-center mx-auto ">
              <h5 text-center>Empty Cart</h5>
              <span class="far fa-shopping-cart fa-6x"></span>
            </div>
          </div>
          <div v-else>
            <nav v-if="programs.length > 1" class="nav nav-pills flex-column flex-sm-row">
              <button
                  v-for="enrolled_courses in programs"
                  class="flex-sm-fill text-sm-center nav-link"
                  :class="'bg-' + enrolled_courses.program + '-light'"
                  :key="enrolled_courses.program"
                  v-scroll-to="'#' + enrolled_courses.program"
              >
                {{ enrolled_courses.name }}
              </button>
            </nav>
            <div v-if="enrolled_courses.count > 0" class="w-100">
              <program-cart
                  :enrolled_courses="enrolled_courses"
                  v-bind:should_update="true"
                  :modalShown="modalShown"
                  @close="hideModal"
              ></program-cart>
            </div>
            <div
                v-for="enrolled_courses in programs"
                class="w-100"
                :class="'border-' + enrolled_courses.program"
                :key="'enrolled_courses-' + enrolled_courses.program"
            >
              <program-cart
                  :enrolled_courses="enrolled_courses"
                  v-bind:should_update="true"
                  :modalShown="modalShown"
                  :key="'cart-' + enrolled_courses.program"
                  @close="hideModal"
              ></program-cart>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="hideModal()"
          >
            Close
          </button>
        </div>
      </div>
    </v-dialog>
        </MountingPortal>
  </span>
</template>

<script>
import { VDialog } from 'vuetensils/src/components'
import isNil from 'lodash/isNil'
import { isEmpty } from 'lodash/core'
import ProgramCart from './ProgramCart'
import { InitialState } from '@/utilities/cs-utilities'
import { mapActions, mapGetters } from 'vuex'
import { GET_ENROLLED_COURSES, UPDATE_REGISTRATIONS } from '@/constants/mutation-types'
import { currency, plural } from '@/utilities/stringFormatters'

export default {
  name: 'ShoppingCart',
  components: {
    ProgramCart: ProgramCart,
    VDialog
  },
  data () {
    return {
      modalShown: false,
      htmlContent: null,
      cartCourses: {},
      enrolled_courses: {},
      total_count: 0,
      stickyOffset: {
        bottom: false,
        top: 100,
        sticked: true
      },
      stickySide: 'top',
      stickyZIndex: 1000
    }
  },
  created () {
    if (this.loggedIn) {
      this.getCartCourses()
    }
    window.cfEventBus.$on('update-cart', this.updateCartRemote)
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      session: 'selectedSession',
      students: 'students',
      member: 'registrationMember',
      isManager: 'gteManager',
      isLoggedIn: 'loggedIn',
      enrolledCourses: 'enrolledCourses',
      programsEnrolledCourses: 'programsEnrolledCourses',
      program: 'program'
    }),
    initialState () {
      return InitialState()
    },
    loggedIn () {
      const initialState = InitialState()
      if (!isEmpty(initialState.auth_member)) {
        return true
      }
      return false
    },
    offset () {
      return {
        top: 60
      }
    },
    count () {
      if (isEmpty(this.programsEnrolledCourses)) {
        return 0
      }
      return this.programsEnrolledCourses.count
    },
    programs () {
      const enrolledPrograms = this.programsEnrolledCourses
      const result = []
      if (!isNil(enrolledPrograms)) {
        if (typeof enrolledPrograms[this.program.slug] !== 'undefined') {
          const enrolledProgram = enrolledPrograms[this.program.slug]
          if (enrolledProgram.count > 0) {
            if (program !== this.program.slug) {
              result.push(enrolledProgram)
            }
          }
        }
        for (var program in enrolledPrograms) {
          if (program !== 'count') {
            const enrolledProgram = enrolledPrograms[program]
            if (enrolledProgram.count > 0) {
              if (program !== this.program.slug) {
                result.push(enrolledProgram)
              }
            }
          }
        }
      }
      return result
    }
  },
  methods: {
    ...mapActions({
      getEnrolledCourses: GET_ENROLLED_COURSES,
      updateRegistrations: UPDATE_REGISTRATIONS
    }),
    currency,
    plural,
    getCartCourses () {
      this.getEnrolledCourses()
    },
    updateCartRemote (program) {
      console.log('cart.updateCartRemote', program)
    },
    showModal: function () {
      this.modalShown = true
    },
    hideModal () {
      this.modalShown = false
    }
  }
}
</script>
<style>

</style>
<style scoped>
#cart2 {
  position: sticky;
  top: 80px;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.carts-layer-counter {
  overflow: visible;
  width: fit-content; /* this may not working with IE 😩 */
}
</style>
