import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ENROLLED_COURSES,
  UPDATE_ENROLLMENT
} from '../../constants/mutation-types'
import { MANAGER_API } from '../../utilities/http-common'

export const groupMethods = {
  computed: {
    ...mapGetters({
      member: 'registrationMember',
      registration: 'registration',
      enrolledCourses: 'enrolledCourses',
      session: 'selectedSession',
      program: 'program'
    })
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES,
      updateEnrollment: UPDATE_ENROLLMENT
    }),
    studentsWhoCanEnrollInGroup: function (group, students) {
      function isInGrade (value) {
        if (typeof value.grade === 'undefined') {
          return false
        }
        return this.includes(value.grade)
      }

      if (typeof this.students !== 'undefined') {
        const courses = group.courses
        const len = courses.length
        let i
        for (i = 0; i < len; i++) {
          const course = courses[i]
          students = students.filter(isInGrade, course.grade_keys)
        }
        return students
      } else {
        return []
      }
    },
    hasStudentsWhoCanEnrollInGroup: function (group, students) {
      const result = this.studentsWhoCanEnrollInGroup(group, students)
      if (typeof result === 'undefined' || result.length === 0) {
        return false
      }
      return true
    },
    enrollGroup (student, event) {
      event.target.disabled = true
      const enrolledCourse = {
        group_id: this.group.id,
        student_id: student.id,
        registration_id: this.registration.id,
        member_id: this.registration.member_id
      }
      const url = '/enrolled_courses/enroll_group'
      MANAGER_API.put(url, enrolledCourse)
        .then(response => {
          const data = response.data
          this.updateEnrollment()
          if (data.result.isError === false) {
            this.$notify({
              group: 'enrollment',
              type: 'success',
              title: this.group.name,
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            this.processed = true
          } else {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.group.name,
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
          }
          event.target.disabled = false
        })
        .catch(e => {
          event.target.disabled = false
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: this.group.name,
            text: e.message,
            duration: 2000,
            speed: 1000
          })
          event.target.disabled = false
        })
    },
    unenrollGroup (student, event) {
      event.target.disabled = true
      const enrolledCourse = {
        group_id: this.group.id,
        student_id: student.id,
        registration_id: this.registration.id
      }
      const url = '/enrolled_courses/unenroll_group'
      MANAGER_API.delete(url, { params: enrolledCourse })
        .then(response => {
          const data = response.data
          event.target.disabled = false
          this.updateEnrollment()
          if (data.result.isError === false) {
            this.$notify({
              group: 'enrollment',
              type: 'info',
              title: this.group.name,
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            this.processed = true
          } else {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.group.name,
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
          }
          event.target.disabled = false
        })
        .catch(e => {
          event.target.disabled = false
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: this.group.name,
            text: e.message,
            duration: 2000,
            speed: 1000
          })
          event.target.disabled = false
        })
    },
    addGroupToCart (student, event) {
      event.target.disabled = true
      const enrolledGroup = {
        course_bulk_group_id: this.group.id,
        student_id: student.id,
        registration_id: this.registration.id,
        member_id: this.member.id
      }
      this.$http
        .put(
          '/api/programs/{programSlug}/enrolled_courses/add_group',
          enrolledGroup,
          { params: { programSlug: this.program.slug } }
        )
        .then(
          response => {
            return response.json()
          },
          // eslint-disable-next-line no-unused-vars
          response => {
            event.target.disabled = false
            return alert('Could not add ' + this.group.name + ' to cart!')
          }
        )
        .then(response => {
          event.target.disabled = false
          const result = response.result

          if (
            typeof result.isError !== 'undefined' &&
            result.isError === false
          ) {
            const enrolledCourses = response.enrolled_courses
            this.updateEnrolledCourses(enrolledCourses)
            if (typeof result.success_message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: this.group.name,
                text: result.success_message,
                duration: 2000,
                speed: 1000
              })
            }
            if (typeof result.failed_message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'warning',
                title: this.group.name,
                text: result.failed_message,
                duration: 2000,
                speed: 1000
              })
            }
          } else if (result.isError === true) {
            if (typeof result.success_message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'warning',
                title: this.group.name,
                text: result.failed_message,
                duration: 2000,
                speed: 1000
              })
            }
            if (typeof result.message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.group.name,
                text: result.message,
                duration: 2000,
                speed: 1000
              })
            }
          }
        })
    },
    removeGroupFromCard (student, group, slug, registration, event) {
      event.target.disabled = true
      const enrolledGroup = {
        course_bulk_group_id: group.id,
        student_id: student.id,
        registration_id: registration.id,
        programSlug: slug
      }
      this.$http
        .delete('/api/programs/{programSlug}/enrolled_courses/delete_group', {
          params: enrolledGroup
        })
        .then(
          response => {
            return response.json()
          },
          // eslint-disable-next-line no-unused-vars
          response => {
            event.target.disabled = false
            return alert('Could not remove ' + group.name + ' from cart!')
          }
        )
        .then(response => {
          event.target.disabled = false
          const result = response.result

          if (
            typeof result.isError !== 'undefined' &&
            result.isError === false
          ) {
            const enrolledCourses = response.enrolled_courses
            this.updateEnrolledCourses(enrolledCourses)
            if (typeof result.success_message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'info',
                title: group.name,
                text: result.success_message,
                duration: 2000,
                speed: 1000
              })
            }
            if (typeof result.failed_message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: group.name,
                text: result.failed_message,
                duration: 2000,
                speed: 1000
              })
            }
          } else if (result.isError === true) {
            if (typeof result.success_message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'warning',
                title: group.name,
                text: result.success_message,
                duration: 2000,
                speed: 1000
              })
            }
            if (typeof result.message !== 'undefined') {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: group.name,
                text: result.message,
                duration: 2000,
                speed: 1000
              })
            }
          }
        })
    },
    deleteGroupFromCart (student, event) {
      this.removeGroupFromCard(
        student,
        this.group,
        this.program.slug,
        this.registration,
        event
      )
    }
  }
}
